import React from 'react';

function Selector({ selectedEdition, onSelect }) {
  return (
    <div className="selector">
      <button
        className={selectedEdition === 'standard' ? 'active' : ''}
        onClick={() => onSelect('standard')}
      >
        Standard <span className="edition-text">Edition</span>
      </button>
      <button
        className={selectedEdition === 'premium' ? 'active' : ''}
        onClick={() => onSelect('premium')}
      >
        Premium <span className="edition-text">Edition</span>
      </button>
      <button
        className={selectedEdition === 'collectors' ? 'active' : ''}
        onClick={() => onSelect('collectors')}
      >
        Collector's <span className="edition-text">Edition</span>
      </button>
    </div>
  );
}

export default Selector;
