import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Selector from './Selector';
import PurchasePanel from './PurchasePanel';
import Figurine from './Figurine';
import Success from './Success';
import Failure from './Failure';
import Counter from './Counter';
import logo from './assets/logo.png';

function App() {
  const [selectedEdition, setSelectedEdition] = useState('standard');
  const [panelState, setPanelState] = useState('collapsed');
  const [isSoundActive, setIsSoundActive] = useState(true);
  const audioRef = useRef(null);

  const videoSources = {
    standard: 'standard.webm',
    premium: 'premium.webm',
    collectors: 'collectors.webm'
  };

  const videoClasses = {
    standard: '',
    premium: '',
    collectors: 'collectors-video'
  };

  const fallbackImages = {
    standard: 'standard_fallback.png',
    premium: 'premium_fallback.png',
    collectors: 'collectors_fallback.png'
  };

  const handleSelect = (edition) => {
    setSelectedEdition(edition);
    setPanelState('collapsed');
    console.log('Selected edition:', edition, 'Video source:', videoSources[edition]);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>

              <img src={logo} className="logo" alt="Logo" />
              <video className="Background-video" autoPlay loop muted playsInline>
                <source src="particles-compressed.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <audio ref={audioRef} src="/background-music.mp3" loop />
              <div className="top-right-buttons">
                <div className={`sound-button ${isSoundActive ? 'active' : 'inactive'}`} onClick={() => setIsSoundActive(!isSoundActive)} />
                <div className="back-button" onClick={() => window.location.href = 'https://nemonium.com'} />
              </div>
              <div className="footer-text">©2024 OMEN</div>
              <Figurine
                videoSrc={videoSources[selectedEdition]}
                videoClass={videoClasses[selectedEdition]}
                fallbackImage={fallbackImages[selectedEdition]}
              />
              <Selector selectedEdition={selectedEdition} onSelect={handleSelect} />
              <Counter />
              <PurchasePanel selectedEdition={selectedEdition} panelState={panelState} setPanelState={setPanelState} />
            </>
          } />
          <Route path="/success" element={<Success selectedEdition={selectedEdition}/>} />
          <Route path="/error" element={<Failure />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
