import React, { useEffect, useRef } from 'react';

function Figurine({ videoSrc, videoClass, fallbackImage }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && !isMobile()) {
      videoRef.current.load();
    }
  }, [videoSrc]);

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  return isMobile() ? (
    <img src={fallbackImage} alt="Mobile Display" className={`App-video ${videoClass}`} />
  ) : (
    <video ref={videoRef} className={`App-video ${videoClass}`} autoPlay loop muted playsInline>
      <source src={videoSrc} type="video/webm" />
      Your browser does not support the video tag.
    </video>
  );
}

export default Figurine;
