import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './Success.css';
import './PurchasePanel.css';
import './App.css';

function Success({ selectedEdition }) {
  const navigate = useNavigate();  // Initialize useNavigate

  const handleCloseClick = () => {
    navigate('/');  // Navigate to the root route
  };

  let [searchParams, setSearchParams] = useSearchParams()

  
  if (searchParams.get("id")) {
    fetch("https://omen.tenancio.com/completeTransaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        tx_id: searchParams.get("id"),
      })
    })
  }

  return (
    <div className="success-container">
      <video className="Background-video" autoPlay loop muted>
        <source src="particles-compressed.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="success-panel">
        <div className="success-text-container">
          <div className="b1">Purchase Successful</div>
          <div className="p1">Please allow up to 6 weeks for your pre-order shipment to fulfill.</div>
        </div>
        <div className="video-container">
          <video className="success-video" autoPlay loop muted>
            <source src={`${selectedEdition}_success.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <button className="close-button" onClick={handleCloseClick}>Close</button>
      </div>
    </div>
  );
}

export default Success;
