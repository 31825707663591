import React, { useEffect, useState } from 'react';
import './Counter.css';

function Counter() {
    const [standardRemaining, setStandardRemaining] = useState(0);
    const [premiumRemaining, setPremiumRemaining] = useState(0);
    const [collectorsRemaining, setCollectorsRemaining] = useState(0);

    const getNumberRemaining = async () => {

        const res = await fetch(`https://omen.tenancio.com/counter`)
        const res_json = await res.json()

        const std_amnt = 87 - res_json.total["collectors"] - res_json.total["standard"] - 24
        const prem_amnt = 57 - res_json.total["collectors"] - res_json.total["premium"] - 13
        let col_amnt = 27 - res_json.total["collectors"] - 11
        if (std_amnt < col_amnt || prem_amnt < col_amnt) {
            col_amnt = Math.min(std_amnt, prem_amnt)
        }   
        
        return {standard: std_amnt, premium: prem_amnt, collectors: col_amnt};
    }

    getNumberRemaining().then((remaining) => {
        setStandardRemaining(remaining.standard)
        setPremiumRemaining(remaining.premium)
        setCollectorsRemaining(remaining.collectors)
    })

    useEffect(() => {
        const intervalId = setInterval(async () => {
            getNumberRemaining().then((remaining) => {
                setStandardRemaining(remaining.standard)
                setPremiumRemaining(remaining.premium)
                setCollectorsRemaining(remaining.collectors)
            })
        }, 10000)

        return () => clearInterval(intervalId);
    }, [])
    

    
    return (
        <div className="counter-container">

            <div className="counter-text">Standard Edition: {standardRemaining}</div>
            <div className="counter-text">Premium Edition: {premiumRemaining}</div>
            <div className="counter-text">Collector's Edition: {collectorsRemaining}</div>
        </div>
    );
}

export default Counter;
