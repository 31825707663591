import React, { useState, useRef } from 'react';
import './PurchasePanel.css';
import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import { Lucid } from "lucid-cardano";
import { useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner'

const stripePromise = loadStripe('pk_live_51M9HS0LHlxhcGykWbhRKzs5VeychoHTGVS5iScEdUzdWg8qaMxjKU5iyVNX8MwzvhaXbGJSbJcCeTzKeGF222BGv00RToc1WqX');

const lucid = await Lucid.new()

function PurchasePanel({ selectedEdition, panelState, setPanelState }) {
  
    const navigate = useNavigate();
    
    const [formFields, setFormFields] = useState({
      firstName: '',
      lastName: '',
      streetAddress: '',
      city: '',
      stateProvince: '',
      zipCode: '',
      btcAddress: '',
      emailAddress: ''
    });
    
    const [previousStates, setPreviousStates] = useState([]);
    const [cardanoApi, setCardanoApi] = useState();
    const [waiting, setWaiting] = useState(false);

    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const streetAddressRef = useRef(null);
    const cityRef = useRef(null);
    const stateProvinceRef = useRef(null);
    const zipCodeRef = useRef(null);
    const btcAddressRef = useRef(null);
    const emailAddressRef = useRef(null);

    const handleFocus = (ref) => {
      const isMobile = window.innerWidth <= 768;
      const panel = ref.current.closest('.purchase-panel');
      
      if (panel && isMobile) {
          panel.style.height = '70vh'; // Adjust the height on mobile
      }
    };
  
    const handleBlur = () => {
        const isMobile = window.innerWidth <= 768;
        const panel = document.querySelector('.purchase-panel');
        
        if (panel && isMobile) {
            panel.style.height = ''; // Reset height when focus is lost
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields({ ...formFields, [name]: value });
    };


    const handleMouseEnter = () => {
      if (panelState === 'collapsed') {
          setPanelState('product-details');
      }
    };
    
    const handleMouseLeave = () => {
      if (panelState === 'product-details') {
          setPanelState('collapsed');
      }
    };
    
    const handlePreOrderClick = (event) => {
        event.stopPropagation();
        setPreviousStates(prev => [...prev, panelState]);
        setPanelState('payment-method');
    };

    const handleCardBtnClick = async (event) => {
      event.stopPropagation();
      const stripe = await stripePromise;
      console.log(stripe);
      const productPrices = {
        'standard': 'price_1PtnulLHlxhcGykWCSLAOPCR',
        'premium': 'price_1Ptnv0LHlxhcGykWoZTCSwa8',
        'collectors': 'price_1PtnvELHlxhcGykWPHk9p6lM'
      };
      const shippingRateId = 'shr_1PwLs1LHlxhcGykW2rpkQzod';
      const selectedPriceId = productPrices[selectedEdition];
      if (await getNumberRemaining() > 0) {
        try {
          console.log("creating session")
          const response = await fetch('https://omen.tenancio.com/create-checkout-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              priceId: selectedPriceId,
              shippingRateId: shippingRateId,
              ada_address: formFields.adaAddress,
              btc_address: formFields.btcAddress
            }),
          });
    
          const session = await response.json();
          console.log("session created")
          addPendingCardTx(session.tx_id, formFields.adaAddress, formFields.btcAddress)

          // navigate to Stripe Checkout
          console.log("redirecting")
          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });

    
          if (result.error) {
            console.error(result.error.message);
            await fetch("https://omen.tenancio.com/removePendingTx", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                txHash: session.tx_id
              })
            })
            navigate("/error")
          } else {
            completeCardTx(session.tx_id)
            navigate("/success")
          }
        } catch (error) {
          console.error('Error:', error);
          navigate("/error")
        }
      } else {
        console.error("None left to buy!")
        navigate("/error")
        //TODO show some kind of error message
      }
    };

    const handleAdaBtnClick = (event) => {
      event.stopPropagation();
      setPreviousStates(prev => [...prev, panelState]);
      setPanelState('wallet-options')
    };

    const handleNamiBtnClick = async (event) => {
      event.stopPropagation();
      setPreviousStates(prev => [...prev, panelState]);
      try {
        const api = await window.cardano.nami.enable();
        if (await api.getNetworkId() != 1) throw("Incorrect wallet network");
        console.log("Nami wallet connected:", api);
        setCardanoApi(api)
        setPanelState('delivery-information');
      } catch (err) {
        console.error("Failed to connect to Nami wallet:", err);
        navigate("/error")
      }
    };

    const handleEternlBtnClick = async (event) => {
      event.stopPropagation();
      setPreviousStates(prev => [...prev, panelState]);
      try {
          const api = await window.cardano.eternl.enable();
          if (await api.getNetworkId() != 1) throw("Incorrect wallet network");
          console.log("Eternl wallet connected:", api);
          setCardanoApi(api)
          setPanelState('delivery-information');
      } catch (err) {
          console.error("Failed to connect to Eternl wallet:", err);
          navigate("/error")
      }
    };

    const handleTyphonBtnClick = async (event) => {
      event.stopPropagation();
      setPreviousStates(prev => [...prev, panelState]);
      try {
          const api = await window.cardano.typhon.enable();
          if (await api.getNetworkId() != 1) throw("Incorrect wallet network");
          console.log("Eternl wallet connected:", api);
          setCardanoApi(api)
          setPanelState('delivery-information');
      } catch (err) {
          console.error("Failed to connect to Typhon wallet:", err);
          navigate("/error")
      }
    };

    const handleVesprBtnClick = async (event) => {
      event.stopPropagation();
      setPreviousStates(prev => [...prev, panelState]);
      try {
          const api = await window.cardano.vespr.enable();
          if (await api.getNetworkId() != 1) throw("Incorrect wallet network");
          setCardanoApi(api)
          console.log("VESPR wallet connected:", api);
          setPanelState('delivery-information');
      } catch (err) {
          console.error("Failed to connect to VESPR wallet:", err);
          navigate("/error")
      }
    };

    const handleFlintBtnClick = async (event) => {
      event.stopPropagation();
      setPreviousStates(prev => [...prev, panelState]);
      try {
          const api = await window.cardano.flint.enable();
          if (await api.getNetworkId() != 1) throw("Incorrect wallet network");
          setCardanoApi(api)
          console.log("Flint wallet connected:", api);
          setPanelState('delivery-information');
      } catch (err) {
          console.error("Failed to connect to Flint wallet:", err);
          navigate("/error")
      }
    };



    const handleBackBtnClick = () => {
      setPreviousStates(prev => {
        const newPrevStates = [...prev];
        const lastState = newPrevStates.pop();
        if (lastState) {
          setPanelState(lastState);
        }
        return newPrevStates;
      });
    };

    const handleAdaPurchaseClick = async (e) => {
      try {
        if (await getNumberRemaining() > 0) {
          const ada_address = (await cardanoApi.getUsedAddresses())[0]
          console.log(ada_address)
          setWaiting(true);
          const res = await fetch("https://omen.tenancio.com/buildTransaction", {
            method: "POST",
            "headers": {
              'Content-Type': "application/json"
            },
            "body": JSON.stringify({
              ada_address: ada_address,
              btc_address: formFields.btcAddress,
              type: selectedEdition,
              first_name: formFields.firstName,
              last_name: formFields.lastName,
              email: formFields.emailAddress,
              address: formFields.streetAddress,
              city: formFields.city,
              post_code: formFields.zipCode,
              state: formFields.stateProvince
            })
          })
          const res_json = await res.json()
          const tx = await lucid.fromTx(res_json.tx);
          try {
            lucid.selectWallet(cardanoApi);

            const witness = await tx.partialSign();
            console.log(witness)
            const submit_res = await fetch("https://omen.tenancio.com/submitTransaction", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                tx: tx.toString(),
                witness: witness
              })
            })
            const submit_res_json = await submit_res.json()
            if (submit_res_json.status === "Success") {
              // TODO show some kind of success message
              navigate("/success")
              return;
            } else {
              // TODO show some kind of error message
              console.log(submit_res_json.error_message)
              navigate("/error")
              return;
            }
          } catch (error) {
            console.log(error)
            await fetch("https://omen.tenancio.com/removePendingTx", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                txHash: tx.toHash()
              })
            })
            navigate("/error")
          }
        } else {
          console.log("None left to buy!")
          navigate("/error")
          // TODO show some kind of error message
        }
      } catch (error) {
        console.log("error")
        navigate("/error")
      }
    }

    const addPendingCardTx = async (id, ada_address, btc_address) => {
      await fetch("https://omen.tenancio.com/addPendingTransaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          tx_id: id,
          type: selectedEdition,
          ada_address: ada_address,
          btc_address: btc_address
        })
      })
    }

    const completeCardTx = async (id) => {
      await fetch("https://omen.tenancio.com/completeTransaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          tx_id: id,
        })
      })
    }

    const getNumberRemaining = async () => {

        const res = await fetch(`https://omen.tenancio.com/counter`)
        const res_json = await res.json()

        const std_amnt = 87 - res_json.total["collectors"] - res_json.total["standard"] - 24
        const prem_amnt = 57 - res_json.total["collectors"] - res_json.total["premium"] - 13
        let col_amnt = 27 - res_json.total["collectors"] - 11
        if (std_amnt < col_amnt || prem_amnt < col_amnt) {
            col_amnt = Math.min(std_amnt, prem_amnt)
        }  
        
        return {standard: std_amnt, premium: prem_amnt, collectors: col_amnt}[selectedEdition];
    }

    const requiredFields = ['firstName', 'lastName', 'streetAddress', 'city', 'stateProvince', 'zipCode'];
    const allRequiredFieldsFilled = requiredFields.every(field => formFields[field].trim() !== '');

    const getProductDetails = (edition) => {
      switch (edition) {
        case 'standard':
          return {
            title: 'Standard Edition',
            price: '$250',
            description: 'Noah, the first physical collectible in the OMEN ecosystem.',
            height: '18cm (7")',
            totalPrice: '$300',
          };
        case 'premium':
          return {
            title: 'Premium Edition',
            price: '$350',
            description: 'Premium Noah collectible with exclusive features.',
            height: '30cm (11")',
            totalPrice: '$400',
          };
        case 'collectors':
          return {
            title: 'Collector\'s Edition',
            price: '$550',
            description: 'Collector\'s Edition with all premium features and more.',
            height: '30cm / 7cm',
            totalPrice: '$600',
          };
        default:
          return {
            title: 'Standard Edition',
            price: '$550',
            description: 'Noah, the first physical collectible in the OMEN ecosystem.',
            height: '18cm (7")',
            totalPrice: '$300',
          };
      }
    };
  
    const productDetails = getProductDetails(selectedEdition);

  const renderContent = () => {
    const isMobile = window.innerWidth <= 768;

    switch (panelState) {
      case 'collapsed':
        return (
            <div className="purchase-window-container">
                <div className="title-container">
                    <div className="title-price">
                        <div className="b1">{productDetails.title}</div>
                        <div className="b1">{productDetails.price}</div>
                    </div>
                    <div className="p1">{isMobile ? "Tap to view details" : productDetails.description}</div>
                </div>
                <button className="pre-order-btn" onClick={handlePreOrderClick}>Pre-order</button>
            </div>
        );
      case 'product-details':
        return (
            <div className="purchase-window-container">
            <div className="title-container">
              <div className="title-price">
                <div className="b1">{productDetails.title}</div>
                <div className="b1">{productDetails.price}</div>
              </div>
              <div className="p1">{productDetails.description}</div>
            </div>
            <div className="product-details-container">
              <div className="b2">Product Specifications</div>
              <hr />
              <div className="product-specification">
                <div className="p2">Height:</div>
                <div className="p2">{productDetails.height}</div>
              </div>
              <div className="product-specification">
                <div className="p2">Material:</div>
                <div className="p2">Resin and PU</div>
              </div>
              <hr />
              <div className="p1">Use a soft brush and a damp cloth with mild soap to gently clean.</div>
              <hr />
              <div className="product-specification">
                <div className="p3">OMEN Ordinal</div>
                <div className="p3">₿ FREE</div>
              </div>
              <div className="product-specification">
                <div className="p2">Shipping (International):</div>
                <div className="p2">$50</div>
              </div>
            </div>
            <button className="pre-order-btn" onClick={handlePreOrderClick}>Pre-order</button>
          </div>
        );
      case 'payment-method':
        return (
            <div className="purchase-window-container">
                <div className="title-container">
                  <div className="title-price">
                    <div className="b1">Payment Method</div>
                    <button className="purchase-back-btn" onClick={handleBackBtnClick}></button>
                  </div>
                  <div className="p1">Please select your payment method.</div>
                </div>
                <div className="payment-option-container">
                    <button className="card-btn" onClick={() => setPanelState('payment-method-card-details')}>Card Payments</button>
                    <button className="ada-btn" onClick={handleAdaBtnClick}>ADA Payments</button>
                </div>
            </div>
        );
      case 'payment-method-card-details':
        return (
            <div className="purchase-window-container-card">
                <div className="title-container">
                  <div className="title-price">
                    <div className="b1">Wallet Details</div>
                    <button className="purchase-back-btn" onClick={handleBackBtnClick}></button>
                  </div>
                  <div className="p1">Please enter your ADA and BTC addresses.</div>
                </div>
                <div className="input-details card">
                  <div className="input-row">
                    <input type="text" className="input full-width" name="adaAddress" placeholder="ADA address" value={formFields.adaAddress} onChange={handleInputChange} onBlur={handleBlur} autoComplete="off"/>
                  </div>
                  <div className="input-row">
                    <input type="text" className="input full-width" name="btcAddress" placeholder="Ordinals address" value={formFields.btcAddress} onChange={handleInputChange} onBlur={handleBlur} autoComplete="off"/>
                  </div>
                </div>
                <button className="card-btn" onClick={handleCardBtnClick}>Purchase</button>
            </div>
        );
      case 'wallet-options':
        return (
            <div className="purchase-window-container">
              <div className="title-container">
                  <div className="title-price">
                    <div className="b1">Select Wallet</div>
                    <button className="purchase-back-btn" onClick={handleBackBtnClick}></button>
                  </div>
                <div className="p1">Please select your preferred ADA wallet.</div>
              </div>
              <div className="payment-option-container">
                <button className="nami-btn" onClick={handleNamiBtnClick}>Nami</button>
                <button className="eternl-btn" onClick={handleEternlBtnClick}>Eternl</button>
                <button className="typhon-btn" onClick={handleTyphonBtnClick}>Typhon</button>
                <button className="flint-btn" onClick={handleFlintBtnClick}>Flint</button>
                <button className="vespr-btn" onClick={handleVesprBtnClick}>VESPR</button>
              </div>
            </div>
          );
      case 'delivery-information':
        return (
            <div>
              <div className="title-container">
                  <div className="title-price">
                    <div className="b1">Delivery Details</div>
                    <button className="purchase-back-btn" onClick={handleBackBtnClick}></button>
                  </div>
                <div className="p1">Please enter your delivery information.</div>
              </div>
              <div className="input-details">
                  <div className="input-row">
                      <input type="text" className="input" name="firstName" placeholder="First name" value={formFields.firstName} onChange={handleInputChange} onFocus={() => handleFocus(firstNameRef)} onBlur={handleBlur} ref={firstNameRef} autoComplete="off"/>
                      <input type="text" className="input" name="lastName" placeholder="Last name" value={formFields.lastName} onChange={handleInputChange} onFocus={() => handleFocus(lastNameRef)} onBlur={handleBlur} ref={lastNameRef} autoComplete="off"/>
                  </div>
                  <div className="input-row">
                      <input type="text" className="input full-width" name="streetAddress" placeholder="Street address" value={formFields.streetAddress} onChange={handleInputChange} onFocus={() => handleFocus(streetAddressRef)} onBlur={handleBlur} ref={streetAddressRef} autoComplete="off"/>
                  </div>
                  <div className="input-row">
                      <input type="text" className="input" name="city" placeholder="City" value={formFields.city} onChange={handleInputChange} onFocus={() => handleFocus(cityRef)} onBlur={handleBlur} ref={cityRef} autoComplete="off"/>
                      <input type="text" className="input" name="stateProvince" placeholder="State/Province" value={formFields.stateProvince} onChange={handleInputChange} onFocus={() => handleFocus(stateProvinceRef)} onBlur={handleBlur} ref={stateProvinceRef} autoComplete="off"/>
                  </div>
                  <div className="input-row">
                      <input type="text" className="input" name="zipCode" placeholder="ZIP Code" value={formFields.zipCode} onChange={handleInputChange} onFocus={() => handleFocus(zipCodeRef)} onBlur={handleBlur} ref={zipCodeRef} autoComplete="off"/>
                  </div>
                  <div className="input-row">
                      <input type="text" className="input" name="btcAddress" placeholder="Ordinals Address" value={formFields.btcAddress} onChange={handleInputChange} onFocus={() => handleFocus(btcAddressRef)} onBlur={handleBlur} ref={btcAddressRef} autoComplete="off"/>
                  </div>
                  <div className="input-row">
                      <input type="text" className="input" name="emailAddress" placeholder="Email address (optional)" value={formFields.emailAddress} onChange={handleInputChange} onFocus={() => handleFocus(emailAddressRef)} onBlur={handleBlur} ref={emailAddressRef} autoComplete="off"/>
                  </div>
              </div>
              

              <div className="payment-details-container">
              <hr />
                <div className="product-specification">
                  <div className="p2">Shipping (International):</div>
                  <div className="p2">$50</div>
                </div>
                <div className="product-specification">
                  <div className="b2">Total (incl. tax):</div>
                  <div className="b2">{productDetails.totalPrice}</div>
                </div>
              </div>
              <button className="purchase-btn" disabled={!allRequiredFieldsFilled} style={{ opacity: allRequiredFieldsFilled ? 1 : 0.5 }} onClick={handleAdaPurchaseClick}>
              {
                !waiting ? (<>Purchase</>) : (<TailSpin
                  visible={true}
                  height="20"
                  width="20"
                  color="#F6F6F6"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  />)
              }
              </button>
             </div>
          );
      default:
        return <div>Default Panel State</div>;
    }
  };

  return (
    <div
      className={`purchase-panel ${panelState}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {renderContent()}
    </div>
  );
}

export default PurchasePanel;
