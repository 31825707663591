import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './Failure.css';

function Failure() {
  const navigate = useNavigate();  

  const handleCloseClick = () => {
    navigate('/');  
  };

  let [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get("id")) {
    fetch("https://omen.tenancio.com/removePendingTx", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        txHash: searchParams.get("id")
      })
    })
  }

  return (
    <div className="failure-container">
      <video className="Background-video" autoPlay loop muted>
        <source src="particles-compressed.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="failure-panel">
        <div className="failure-text-container">
          <div className="b1">Transaction Failed</div>
          <div className="p1">Unfortunately, your transaction could not be completed. Please try again.</div>
        </div>
        <button className="close-button" onClick={handleCloseClick}>Close</button>
      </div>
    </div>
  );
}

export default Failure;
